import React, { useEffect } from "react";
import $ from "jquery";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

const Knowledge = () => {
  const lang = "pl";

  useEffect(() => {
    $(".knowledge-wrapper--header").click(function () {
      if (!$(this).parent().hasClass("open")) {
        $(".knowledge-wrapper.open .knowledge-wrapper--header")
          .next()
          .slideUp();
        $(".knowledge-wrapper.open").removeClass("open");
      }
      $(this).next().slideToggle();
      $(this).parent().toggleClass("open");
    });
  });

  return (
    <Layout
      seoTitle="Baza wiedzy"
      lang={lang}
      translationEN="/en/knowledge-base/"
      translationDE="/de/wissensdatenbank/"
    >
      <SmallBanner title="Baza wiedzy" lang={lang} />

      <section className="knowledge-main">
        <div className="container">
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czym się różni paleta EPAL od palety niestandaryzowanej?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Europaleta EPAL 1 to najpopularniejsza w Europie paleta o
                  wymiarach 800×1200mm. Deski blatu (ułożone wzdłużnie) oraz
                  trzy płozy ułożone wzdłuż palety, wykonane są z drewna litego,
                  natomiast wsporniki mogą być wykonane z drewna litego bądź
                  rozdrobnionego. Palety Epal są produktami atestowanymi,
                  znakowanymi w odpowiedni sposób. Ich produkcję oraz naprawę
                  obejmują specjalnie przepisy. Zakup palety standaryzowanej
                  gwarantuje jakość, powtarzalność oraz długą żywotność palety.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Jak należy interpretować oznaczenia na klocku palety EPAL?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Każda europaleta EPAL na środkowym wsporniku posiadać musi
                  numer licencji producenta, rok i miesiąc produkcji.
                  <br />
                  Numer licencji – oznaczenie trzycyfrowe. <br />
                  Rok produkcji – oznaczenie jednocyfrowe. <br />
                  Miesiąc produkcji – oznaczenie dwucyfrowe. <br />
                  Każda nowa europaleta EPAL wyprodukowana w gwarantowanym
                  systemie jakości EPAL, musi zostać poddana obróbce
                  fitosanitarnej ISPM15. Potwierdzeniem wykonania obróbki
                  fitosanitarnej jest umieszczenie stosownego znaku na środkowym
                  wsporniku. <br />
                  <br />
                  <img
                    src={require("../assets/img/faq-ask-2.png")}
                    alt="oznaczenie palet"
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Jak należy rozumieć oznaczenie HT na palecie?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Obróbka termiczna w rozumieniu dokumentu ISPM 15
                  (International Standards for Phytosanitary Measures No 15 )
                  polega na takim wygrzewaniu drewna aby w jego rdzeniu, czyli w
                  nawet najbardziej odległych od powierzchni obszarach,
                  osiągnięta została temperatura co najmniej 56°C. Temperatura
                  ta musi być utrzymana przez co najmniej 30 minut. Jeśli tak
                  się stanie, to uznaje się, że przeprowadzono zabieg termicznej
                  sterylizacji drewna, czyli zdecydowana większość znanych
                  organizmów zostaje nieodwracalnie unieszkodliwiona. Opakowanie
                  drewniane np. paleta poddane takiemu zabiegowi może być
                  oznakowane literami HT od angielskich słów Heat Treatment.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czym się różnią sposoby załadunku "na zakładkę" oraz "jedna na
              drugą"?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  <strong>Na “zakładkę” (one in one):</strong>
                  <br />
                  Palety układane są w konstrukcje gniazdowe, które umożliwiają
                  założenie (włożenie) jednej w drugą. Są one przeplatane w taki
                  sposób, że co druga paleta jest obrócona “do góry nogami” i
                  jej wsporniki mijają się ze wspornikami kolejnej, co pozwala
                  na maksymalne wykorzystanie dostępnej przestrzeni załadunkowej
                  i magazynowej. Taki sposób ułożenia może uniemożliwiać
                  automatyczne pobieranie palet ze słupka na niedostosowanej
                  linii produkcyjnej. <br />
                  <br />
                  <strong>„Jedna na drugą”(one on one):</strong>
                  <br />
                  Palety układane są jedna na drugiej w sztosy kolumnowe. Każda
                  paleta stoi na blacie wierzchnim drugiej.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Jakie korzyści wynikają z załadunku palety jedna na drugą?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Palety ułożone w systemie 1/1 (one on one) są dedykowane
                  odbiorcom, którzy posiadają linie zautomatyzowane do
                  bezpośredniego załadunku palety na taśmę produkcyjną
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Jaką wilgotność ma standardowa paleta?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Wilgotność nowych palet euro nie może przekraczać 22%.
                  Natomiast palety specjalne produkowane na życzenie klienta
                  mogą posiadać dowolną wilgotność.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czym się różni sinizna klocowa od pleśni?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Pleśnienie drewna to zjawisko rozwoju grzybów wynikające z
                  niewłaściwego składowania drewna. Pojawieniu się pleśni
                  sprzyja duża wilgotność, brak obiegu powietrza oraz
                  umiarkowanie wysoka temperatura. Parametry zainfekowanego
                  pleśnią drewna ulegają pogorszeniu przez co paleta może nie
                  spełniać wymaganych norm. Z tego powodu ważne jest by
                  producent palet dysponował odpowiednią infrastrukturą, której
                  zadaniem jest zminimalizowanie zagrożenia wystąpienia pleśni.{" "}
                  <br />
                  <br />
                  Bardzo często naloty pleśni mylone są z sinizną klocową, która
                  wg opinii Instytutu Technologii Drewna w Poznaniu jest jedynie
                  estetyczną zmianą zabarwienia drewna nie pogarszającą
                  parametrów materiału. Paleta z sinizną jest w pełni
                  wartościowym produktem.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czy sinizna klocowa dyskwalifikuje paletę?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Wg opinii Instytutu Technologii Drewna w Poznaniu sinizna
                  klocowa nie wpływa na parametry drewna i tym samym wyrobów
                  gotowych. Palety z widoczną sinizną klocową mogą być bez obaw
                  wykorzystywane jako nośnik towarów.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czy istnieje możliwość skonstruowania palety odpowiedniej do moich
              potrzeb?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Tak. Nasi specjaliści z przyjemnością przedstawią projekt oraz
                  wyprodukują palety odpowiadające Państwa indywidualnym
                  wymaganiom. Istnieje również możliwość wykonania palety według
                  przesłanego przez Państwa rysunku.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              W jakim terminie możecie Państwo dostarczyć palety?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  W przypadku palet EPAL, CP oraz standardowych palet
                  przemysłowych termin realizacji zamówienia w Polsce wynosi 48
                  godzin. W sytuacji dostaw za granicę, czas oczekiwania wynosi
                  2-4 dni roboczych. W przypadku palet specjalnych, termin
                  realizacji pierwszej dostawy wynosi 5-12 dni roboczych i jest
                  uzależniony od dostępności materiału. PalettenWerk oferuje
                  Państwu możliwość utworzenia magazynu buforowego i dostaw na
                  wezwanie. Wówczas czas oczekiwania na palety wynosi 48 godzin
                  w kraju oraz 2-4 dni robocze za granicą.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czy możecie Państwo opatrzyć paletę niestandardowymi znakami (np.
              moje logo)?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Tak. Istnieje możliwość oznakowania palet według wzoru
                  przesłanego przez klienta. Może to być logo firmy lub inny
                  symbol mający na celu wyróżnienie palety.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              W jaki sposób jesteście Państwo w stanie oznakować paletę?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Do nanoszenia symboli wykorzystujemy technikę wypalania (hot
                  stamping) oraz drukowania za pomocą specjalistycznych drukarek
                  w wysokiej rozdzielczości. Dodatkowo istnieje możliwość
                  oznakowania palety poprzez pomalowanie jej obrysu lub całej
                  powierzchni na dowolny kolor w naszych nowoczesnych
                  malarniach.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czy oferujecie Państwo systemy śledzenia palet?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Tak, firma PalettenWerk jako jeden z najbardziej
                  zaawansowanych technologicznie producentów palet oferuje
                  możliwość drukowania na paletach kodów QR, a także wyposażania
                  ich w chipy RFID. Oba rozwiązania pozwalają na dostosowanie do
                  własnego systemu śledzenia.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Na czym polega kontrola jakościowa palet?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Nasz zakład, spełniając restrykcyjne wymagania dotyczące
                  jakości, uzyskał certyfikat ISO 9001:2015. Wymagania te są
                  przestrzegane na każdym etapie produkcji, w którego skład
                  wchodzi wiele procesów i działań.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Jakie certyfikaty posiada Państwa firma?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Firma PalettenWerk posiada wszelkie niezbędne certyfikaty i
                  uprawnienia potrzebne do produkcji palet m.in.: <br />
                  <ul>
                    <li>Licencja EPAL</li>
                    <li>Certyfikat ISO 9001:2015</li>
                    <li>Certyfikat IPPC</li>
                    <li>Certyfikat PEFC</li>
                  </ul>
                  Wymienione certyfikaty i licencje są regularnie odnawiane na
                  podstawie audytów przedstawicieli organizacji, które je
                  wydają.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              W jakiej rejony dostarczacie Państwo palety?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  PalettenWerk, jako jeden z głównych producentów palet w Polsce
                  i Europie eksportuje niemal 70% produkcji, czyli prawie 500
                  tys. palet miesięcznie. Nasi odbiorcy posiadają swoje siedziby
                  w 17 krajach europejskich. Oprócz polskiego największe rynki
                  zagraniczne obejmują Włochy, Niemcy, Szwajcarię, Francję i
                  Austrię. Pozostałe rynki zbytu to: Czechy, Ukraina, Litwa,
                  Słowacja, Rumunia, Dania, Węgry, Belgia, Słowenia, Serbia,
                  Holandia, Hiszpania, Wielka Brytania. Nie boimy się wyzwań i
                  ciągle poszerzamy portfolio naszych odbiorców o kolejne
                  destynacje.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              W jaki sposób przebiega proces sprzedaży palet?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Potrzeba klienta – to główny czynnik inicjujący cały proces.
                  PalettenWerk to jedyna słuszna odpowiedź jeżeli w treści
                  potrzeby pojawia się słowo paleta. <br />
                  Już od pierwszego kontaktu nasi doradcy w sposób indywidualny
                  starają się ustalić faktyczne potrzeby. Ponieważ jak każdy
                  musi dopasować rozmiar butów do siebie tak i dobór
                  odpowiedniej palety gwarantuje komfort i oczywiste
                  oszczędności prowadzenia biznesu. Po przedstawieniu swoich
                  wymagań przez klienta, doradcy handlowi we współpracy z
                  projektantami i działem logistycznym przygotowują ofertę.
                  Pokrywa się ona z oczekiwaniami klienta i zapewnia
                  najkorzystniejszą relację cena-jakość. Klient w sposób aktywny
                  bierze udział w całym procesie mając wpływ na końcowy efekt.
                  Po zaakceptowaniu oferty na życzenie klienta produkuje się
                  próbki, które są mu dostarczane. W ostatnim etapie rozpoczyna
                  się regularna współpraca.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czy używacie Państwo certyfikowanych komponentów?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Legitymując się takim certyfikatami jak PEFC, firma
                  PalettenWerk jest zobligowana do korzystania z usług
                  sprawdzonych dostawców, którzy w swojej ofercie mają wyłącznie
                  certyfikowane produkty. Na straży wszystkiego stoją liczne
                  normy ustanawiane i egzekwowane przez światowe organizacje.
                  Ponadto firma PalettenWerk sama organizuje audyty u swoich
                  dostawców sprawdzając jakość dostarczanego surowca.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Jak mogę złożyć u Państwa zamówienie na palety?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Zamówienia prosimy składać za pośrednictwem poczty
                  elektronicznej u naszych doradców handlowych lub też pod
                  adresem{" "}
                  <a href="mailto:palety@palettenwerk.pl">
                    palety@palettenwerk.pl
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Jaka jest minimalna ilość zamówienia?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Minimalna ilość zamówienia zależy od konstrukcji palety, można
                  ją określić wysyłając do nas zapytanie na adres{" "}
                  <a href="mailto:palety@palettenwerk.pl">
                    palety@palettenwerk.pl
                  </a>
                  . Należy jednak wziąć pod uwagę, że ilość minimalna będzie
                  wyrażona liczbą trzycyfrową.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Jaki jest czas pracy Państwa firmy?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Poszczególne działy naszej firmy mają różne czasy pracy.
                  Obsługa klienta odbywa się w godzinach od 8:00 do 16:00, a
                  załadunki i przyjęcia towarów od 06:00 do 22:00, od
                  poniedziałku do piątku.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              W jaki sposób konstruujecie Państwo ofertę? Jakie czynniki
              wpływają na cenę palet?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Cena palety uzależniona jest od:
                  <ul>
                    <li>ceny rynkowej drewna</li>
                    <li>budowy palety</li>
                    <li>jednorazowego nakładu</li>
                    <li>łącznej, rocznej ilości zamawianych palet</li>W celu
                    otrzymania oferty, prosimy o przesłanie zapytania do
                    wybranego doradcy handlowego lub na adres{" "}
                    <a href="mailto:palety@palettenwerk.pl">
                      palety@palettenwerk.pl
                    </a>
                    .
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czy możecie Państwo utrzymać dla mnie minimalne stany magazynowe?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Istnieje możliwość utrzymywania minimalnych stanów
                  magazynowych, szczegóły można ustalić z naszym doradcą
                  handlowym.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Czym jest paleta 4.0?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Jesteśmy świadkami czwartej rewolucji przemysłowej <a href="https://pl.wikipedia.org/wiki/Czwarta_rewolucja_przemys%C5%82owa" target = "blank">(https://pl.wikipedia.org/wiki/Czwarta_rewolucja_przemys%C5%82owa)</a>. Podstawowym elementem tej rewolucji jest wymiana danych (w szczególności pomiarowych) oraz ich analiza. Do tej technologii wpisuje się również Internet Rzeczy – IoT <a href="https://pl.wikipedia.org/wiki/Internet_rzeczy" target = "blank">(https://pl.wikipedia.org/wiki/Internet_rzeczy)</a> , który jest źródłem danych. Paleta 4.0 to synonim nowoczesnej palety transportowej, wyposażonej w układy pomiarowe, system rejestracji i komunikacji, a także rozwiązania pozwalające na identyfikację, lokalizacje oraz odczyt cech logistycznych.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Do czego służy RFID w palecie?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                RFID to system zdalnej identyfikacji za pomocą fal radiowych <a href="https://pl.wikipedia.org/wiki/RFID" target = "blank">(https://pl.wikipedia.org/wiki/RFID)</a>. Identyfikacja polega na odczytaniu unikalnego numeru (EPC) i powiązanie tego numeru z informacjami na temat danej palety. W naszych rozwiązaniach stosujemy standard RFID-UHF ISO/IEC 18000-6C. Standard ten pozwala na odczyt numeru EPC nawet z odległości 6m. Dzięki temu można odczytywać numery palet np. przejeżdżających przez bramę, w transporcie zbiorowym, w samochodzie, czy na magazynie. Odczyt może być realizowany automatycznie, zatem jest to system do rozpoznawania palety przez układy zrobotyzowane, linie produkcyjne czy zautomatyzowane stanowiska produkcyjne. Oprócz numerów EPC, możliwy jest zapis dodatkowych, własnych informacji, przecz co paleta staje się także nośnikiem danych.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Knowledge;
